import React from "react"
import {graphql} from "gatsby"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import SEO from "components/seo"
import {Row, Container, Section, SectionTitle} from "components/ui"
import Gtwobadge from 'components/ui/gtwobadge.jsx'
import CaseStudyList from './case-study/_caseStudyList.jsx'
import TestimonialList from './case-study/_testimonialList.jsx'
import Capterrabadge from 'components/ui/capterrabadge.jsx';

const ContentPage = ({data}) => {
    return (
        <Layout.Layout>
            <Layout.SEO
              refKey="casestudy"
              title="How SimpleBackups helps securing your data"
              description="SimpleBackups is a backup solution for your server, database, storage and application backups. Find out how we help our customers to secure their data."
            />
            <Layout.HeroEmpty/>
            <Ui.Section>
                <Ui.Container>
                    <Ui.Row>
                        <div className="col-md-12 text-center">
                            <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black text-dark">
                              Our users build awesome products and sleep well!
                            </h1>
                            <p>Developers, agencies and startups are using SimpleBackups to orchestrate their backups.</p>
                        </div>
                        <CaseStudyList posts={data.posts.edges}></CaseStudyList>
                    </Ui.Row>
                </Ui.Container>
            </Ui.Section>
            <Sections.SectionTestimonialExtended />
        </Layout.Layout>
    )
}

export const pageQuery = graphql`
  query CaseStudiesListQuery {
    posts: allMdx(
      filter: {frontmatter: {layout: {eq: "casestudy"}}},
      sort: {fields: frontmatter___date, order: DESC},
      ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            userQuote
            userRole
            userName
            userPhoto
            companyShortDescription
            date(formatString: "DD-MM-YYYY")
          }
        }
      }
    }
  }
`

export default ContentPage
