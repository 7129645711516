import React from "react"
import { Testimonial } from "components/ui"

const ContentPage = () => (

    <div className="grid md:grid-cols-2 gap-8 mt-16">
        <Testimonial
            avatar="testimonials-wb.png"
            name="Will"
            role="Sidekick Digital"
            company="Sidekick Digital"
            content="The simplest products are almost always the best, and this epitomises that. I highly recommend the team working on SimpleBackups."
        />
        <Testimonial
            avatar="testimonials-cs.png"
            name="Chris Soriano"
            role="Clever Talks"
            content="I did not want to install extra packages on my servers to do backups or sync to other cloud services (...). I tested SimpleBackups and ... bam it just works!"
        />
        <Testimonial
            avatar="testimonials-corby.jpeg"
            name="Corby Wenzelbach"
            role="LS Graphics Inc."
            content="Simplebackups has been a great tool for our web development company. We currently have over 200 backups setup across 60 different webservers - all running automated backups! The support team has been great, I highly recommend Simplebackups!"
        />
        <Testimonial
            avatar="testimonials-taddei.jpg"
            name="Marco Taddei"
            role="App Developer - Fairfield Public Schools"
            content="SimpleBackups provides a much-needed backup solution for our critical databases. We run dozens of applications that have complex setups and privacy concerns, and SimpleBackups sync configuration are hands down the best on the market for complex setups."
        />
        <Testimonial
            avatar="testimonials-mr.jpeg"
            name="Mikko Raasina"
            role="CEO at Affordia Oy"
            content="SimpleBackups, as the name implies, is an excellent service that makes it very simple for you to automate your cloud storage backups. Easy to use, caring customer service. I would absolutely recommend it."
        />
        <Testimonial
            avatar="testimonials-johna.jpg"
            name="John A."
            role="Founder, CTO"
            content={`"Simple to use, affordable, good support" As the name implies, it's really easy to set up air-gapped (offsite) backups. We're backing up our RDS database in object-locked storage, especially as an emergency/ransomware backup scenario.`}
        />
        <Testimonial
            avatar="testimonials-philb.jpg"
            name="Philip B."
            role="Web Developer"
            content={`"Fantastic, feature rich and simple to use" It is feature rich with a simple interface and great support.`}
        />
        <Testimonial
            avatar="testimonials-christianb.jpg"
            name="Christian B."
            role="Director of IT at Business"
            content={`"Great, easy to use, and flexible options" We love the simplicity of installing and managing backup jobs through the web interface. Flexible storage options to wasabi. Nice email alerts. Backs up our servers and PostgreSQL db without issues.`}
        />
        <Testimonial
            avatar="testimonials-olivierk.jpg"
            name="Olivier K."
            role="Founder at Business"
            content={`I was looking for an easy backup solution for my clients MySQL databases. After playing around with a couple of other sites which was either too complicated or too expensive for me I found SimpleBackups and signed up for a test account. In a matter of minutes, all was up and running and my DBs was backuped securely to my Dropbox account. I was looking for a solution to have my DBs on my personal Dropbox account. Later I adapted to their own cloud space which also worked after only a couple of clicks. It simply works as promised, easy and reliable! Additional their support is exceptional and I got very fast help for doubts in their internal chat and via email as well. Today this is not as normal as it should be but they actually care for their clients.`}
        />
        <Testimonial
            name="Daniel E."
            role="CTO at Business"
            content={`"Fantastic, feature rich and simple to use" It is feature rich with a simple interface and great support."Great and easy system for all backups" The system is so easy to use (you can configure your backups with ssh in 10 minutes), and the support is 10/10. You can get support and resolve all your issues / doubts in a few minutes.`}
        />
        <Testimonial
            name="Paul Rogers"
            role="Developer"
            content="Just want to say that it looks like you guys have a great product! Been using it for a little while and so far it's the best I've used."
        />
        <Testimonial
            name="Greg Freeman"
            role="Developer"
            content="You guys offer the most responsive service I’ve seen."
        />
        <Testimonial
            name="Bogdan"
            role="President - Infear"
            content="Backups of databases are generally too complicated, specially MongoDB (which we use for anxiety tests), but thanks to SimpleBackups, we just setup a job in dashboard and the rest will take care of itself. Fantastic life-saver!"
        />
        <Testimonial
            name="Ahmad Taha"
            role="Applications Developer Fairfield Public Schools"
            content="SimpleBackups is awesome and very simple to use for backup solutions. We use SimpleBackups for all our projects, and have had an incredible experience so far :) Even if I can make my own simple database backup solution, I'd prefer SimpleBackups due to the exceptional peace of mind it gives. The pricing is also very good and competitive."
        />
        <Testimonial
            avatar="testimonials-default.png"
            role="Developer"
            content="Express setup is awesome!!"
        />
    </div>

)

export default ContentPage
